import React from 'react'
import {
  userEmail,
  userJobTitle,
  userLinks,
  userLocation,
  userLocationUrl,
  userName,
  userPhone
} from '../../site-config'
import { ContactEntry } from '../components/ContactEntry'
import { HeaderSpacer } from '../components/HeaderSpacer'
import { Headline } from '../components/Headline'
import { Page } from '../components/layout/Page'
import { Section } from '../components/Section'
import { SectionBody } from '../components/SectionBody'

export default (props) => {
  return <Page {...props}>
    <Section>
      <HeaderSpacer>
        <h1 className="header-user-name">{userName}</h1>
        <h4 className="header-job-title">{userJobTitle}</h4>
        <ul className="list-inline">
          <li className="list-inline-item">
            <ContactEntry url={'tel:' + userPhone} iconClassName='fas fa-phone' text={userPhone} />
          </li>
          <li className="list-inline-item contact-entry">
            <ContactEntry url={'mailto:' + userEmail} iconClassName='fas fa-at' text={userEmail} />
          </li>
          <li className="list-inline-item contact-entry">
            <ContactEntry url={userLocationUrl} iconClassName='fas fa-home' text={userLocation} />
          </li>
        </ul>
      </HeaderSpacer>
    </Section>
    <Section>
      <Headline>My links</Headline>
      <SectionBody>
        <div className='spacer'>
          {userLinks.map((entry) =>
            <ContactEntry
              big
              key={entry.label}
              label={entry.label}
              url={entry.url}
              text={entry.shortUrl}
              iconClassName={entry.iconClassName}
            />
          )}
        </div>
      </SectionBody>
    </Section>
  </Page>
}
